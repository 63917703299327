import React from "react"
import Seo from "../../components/seo"
import Layout from '../../components/layout'
import * as styles from "./index.module.scss"
import { graphql } from "gatsby"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { html } = markdownRemark
  return (
    <Layout>
      <Seo title={markdownRemark.frontmatter.title} />
      <section className="container">
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className={styles.container}
        ></div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`
